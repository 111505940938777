@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.file-input{
  height: 0;
  width: 0;
  opacity: 0;
}




.imgContainer {

  width: 100%;
  height: 250px;
  position: relative;
  overflow: hidden;


  border-radius: 10px;
}

.imgContainer img{
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  max-height: 110%;
  max-width: 110%;
  }

 
  .user-nav{
    border-bottom: #25274D solid 2px;
  }

  .user-nav-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    max-width: 1200px;
    margin: auto;
  }

  .burger{
    font-size: 2.5rem;
    align-self: center;
  }

  .burger-dark{
    color: black;
  }

  .burger-dark:hover{
    cursor: pointer;
    color: black;
  }

  .burger-light{
    color: white;
  }

  .burger-light:hover{
    cursor: pointer;
    color: #505050;
  } 

  .nav-logo{ 
    max-width: 75px;
  }

  .nav-logo:hover{
    cursor: pointer;
  }

  .nav-open{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }

  .nav-open ul{
    list-style: none;
  }

  .close-nav-button{
    position: absolute;
    top: 18px;
    right: 45px;
    color: black;
    opacity: 0.7;
    font-size: 2.5rem;
    transition: 0.5s;
    padding: 0px;
  }

  .close-nav-button:hover{
    cursor: pointer;
    opacity: 1;
  }

  .nav-list-container{
    max-height: 80%;
    width: 50%;
  }

  .nav-list{
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .nav-list-header{
    margin-bottom: 18px;
    font-weight: 500;
    color: black;
  }
  
  .nav-list-header:hover{
    cursor: default;
  }

  .nav-break{
    margin: 0 0 18px 0;
    border-top: 1.5px solid black ;
  }

  .nav-list-item{
    margin-bottom: 18px;
    font-size: 1.5rem;

  }

  .nav-link{
    text-decoration: none;
    color: #3b3b3b;
    transition: ease-in 0.3s;
    font-weight: 300;
  }

  .nav-link:hover{
    color: #000000;
    filter: drop-shadow(0 10px 10px #353738);
  }


  .home-container{

    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    background-image: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url(https://firebasestorage.googleapis.com/v0/b/image-inception.appspot.com/o/iicoverphotov2.jpg?alt=media&token=e3fb9ce0-7075-42ce-b28c-097152f532c0);
    
    height: 100%;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
  }

.home-text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: white;
  width: 70vw;
  margin: auto;
  
}

.ii-logo{
  width: 100%
}



.content-container{
  max-width: 1200px; 
  margin: auto;
  width: 90vw;
  padding: 4rem 0 0;
}

.content-container h2{
  font-weight: 300;
  padding-bottom: 4rem;
}

.pic-gallery{
  -webkit-column-count: 2;
  -moz-column-count:    2;
  column-count:         2;
  text-align: center;
  margin-bottom: 2rem;
}

.pic-item{
  margin-bottom: 1.5rem;
}

.hover-cursor:hover{
  cursor: pointer;
}

.pic-gallery img{
  width: 95% !important;
  height: auto !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}

.fw-800{
  font-weight: 800;
}

.form-label{
  margin: 0;
  font-weight: 600;
}

.error-message{
  color: red;
}

.modal-95w{
  width: 95%;
  max-width: none;
}

.modal-opacity{
      background-color: rgba(0,0,0,0.88);
}

.pic-modal{
  max-height: 80vh;
  padding: 10px 0;
  max-width: 97%;
}

.arrow{
  color: white;
  opacity: 0.4;
  font-size: 30px;
  transition: 0.5s;
  margin: 0;
}

.arrow:hover{
  cursor: pointer;
  opacity: 1;
}


.arrow-right{
  position: absolute;
  top: 50%;
  right: 2px;
}

.arrow-left{
  position: absolute;
  top: 50%;
  left: 2px
}

.modal-close{
  position: absolute;
  top: 0px;
  right: 7px;
  color: white;
  opacity: 0.7;
  font-size: 24px;
  transition: 0.5s;
  padding: 0px;
  
}

.modal-close:hover{
  cursor: pointer;
  opacity: 1;
}

.modal-text{
  color: white;
  background-color: black;
  border-top: white 2px solid;
}




.form-container{
  max-width: 666px;
  margin: 0 auto;
  padding: 4rem 0 0;
}

form{
  padding: 0 20px;
}

.form-control{
  margin-bottom: 12px;
}


.form-button {
  display: block;
  margin: 0 auto;
  padding: 1rem 2rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  margin-top: 1rem;
  transition: .2s ease-in-out 0s;
}

.form-button:hover{
  transform: scale(1.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.error-message{
  margin-top: 1rem;
  color: red;
}

.tick-icon{
  color: green;
  border: 3px solid;
  display: inline-flex;
  font-size: 100px;
  border-radius: 50%;
  margin-bottom: 3rem;
}

.sent-message{
  font-size: 24px;
  padding: 0.5rem 0;

}

.main-heading{
  font-weight: 300;
  padding-bottom: 4rem;
}



@media (max-width: 46.8em) {
  .pic-gallery {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }

  .nav-open{
    width: 100%;
  }

  .close-nav-button {
    top: 9px;
    right: 18px;
    font-size: 35px;
}

  .nav-list-container {
    width: 76%;
  }

  .content-container, .form-container {
    padding: 2rem 0 0;
  }

  .main-heading{
    padding-bottom: 1rem;
  }

}